<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form" @submit.stop.prevent="onSubmitLogin()">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Login ke MarketWatch</h3>
                <span class="text-muted font-weight-bold font-size-h4">Baru? <a id="kt_login_signup" class="text-primary font-weight-bolder" @click="initRegister">Silahkan mendaftar</a></span>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Username</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="username" v-model="username" readonly="readonly" />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" name="login_password" v-model="login_password" autocomplete="off" />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Login Token</label>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="login_token" v-model="login_token" autocomplete="off" />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">Sign In</button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form class="form" novalidate="novalidate" id="kt_login_signup_form" @submit.stop.prevent="onSubmitRegister()">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Mendaftar</h3>
              </div>
              <div v-if="regstep == 0">
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">Nama Lengkap</label>
                  <div id="email" label="" label-for="example-input-1">
                    <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="nama_lengkap" name="nama_lengkap" v-model="nama_lengkap" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                  <div id="email" label="" label-for="example-input-1">
                    <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="email" name="email" v-model="email" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">Password</label>
                  <div id="email" label="" label-for="example-input-1">
                    <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" name="password" v-model="password" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">Modal 1Inch</label>
                  <div id="limit_1inch" label="" label-for="example-input-1">
                    <select class="form-control" type="limit_1inch" name="limit_1inch" v-model="limit_1inch">
                      <option value="1">&lt; 500</option>
                      <option value="2">600 - 800</option>
                      <option value="3">1000 - 1300</option>
                      <option value="4">1500 - 1800</option>
                      <option value="5">&gt; 2000</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">Modal Matcha</label>
                  <div id="limit_matcha" label="" label-for="example-input-1">
                    <select class="form-control" type="limit_matcha" name="limit_matcha" v-model="limit_matcha">
                      <option value="1">&lt; 50</option>
                      <option value="2">70 - 100</option>
                      <option value="3">200 - 300</option>
                      <option value="4">500 - 700</option>
                      <option value="5">&gt; 1000</option>
                    </select>
                  </div>
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                  <button type="button" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" @click="showForm('signin')">Batal</button>
                  <button type="button" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 ml-4" @click="regstep = 1">Berikutnya</button>
                </div>
              </div>
              <div v-if="regstep == 1">
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">Kode Registrasi</label>
                  <textarea
                    rows="10"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Register Hash"
                    v-model="register_data_hash"
                    readonly="true"
                  />
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                  <button type="button" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" @click.prevent="regstep = 0">Kembali</button>
                  <button type="button" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 ml-4" @click.prevent="regstep = 2">Berikutnya</button>
                </div>
              </div>
              <div v-if="regstep == 2">
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">Token Aktivasi</label>
                  <textarea
                    rows="10"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Kode Aktivasi"
                    v-model="register_token"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                  <button type="button" id="kt_login_signup_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" @click="regstep = 1">Kembali</button>
                  <button ref="kt_login_signup_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" style="width: 150px">Register</button>
                  <button type="button" class="btn btn-light-danger font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" @click="unRegister()">Unreg</button>
                </div>
              </div>
            </form>
          </div>
          <!--end::Signup-->
        </div>
        <!--begin::Content footer-->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
    <b-modal ref="modal-tos" id="modal-tos" title="Persetujuan Pengguna (Terms of Service)" size="xl">
      <div style="height: 400px; overflow: auto;font-size:20px;">
        (1/3) Setelah melakukan pendaftaran aplikasi ini artinya, Saya secara sadar dan tanpa paksaan telah menyetujui semua kesepakatan secara tidak tertulis dengan pengelolah aplikasi sehingga saya
        bersedia menerima semua konsekuensi sepihak dari pengelolah aplikasi jika dikemudian hari melakukan pelanggaran kesepakatan.
        <br />
        <b-form-checkbox v-model="term_1">Saya telah membaca dan memahami.</b-form-checkbox>
        <br />
        <br />
        (2/3) Saya pun telah memahami jika aktifitas ini adalah aktifitas transaksi keuangan beresiko tinggi, sehingga jika suatu saat, apabila terjadi kerugian disebabkan oleh keterlambatan
        pengiriman/ keterlambatan eksekusi/ keterlambatan koneksi/ penyesuaian harga yang cepat/ atau salah kirim. Maka saya tidak akan menuntut / menyalahkan pihak pengelolah aplikasi atau siapapun
        karena saya telah dengan sadar memahami semua resikonya.
        <br />
        <b-form-checkbox v-model="term_2">Saya telah membaca dan memahami.</b-form-checkbox>
        <br />
        <br />
        (3/3) Pengguna aplikasi menyepakati bahwa segala kebijakan yg berkaitan dengan aplikasi merupakan hak prerogatif pengelolah, sehingga pengguna hanya memiliki batasan sebagai pengguna aplikasi
        dan tidak memiliki hak untuk menuntut/meminta keistimewaan aplikasi kepada pengelolah.
        <b-form-checkbox v-model="term_3">Saya telah membaca dan memahami.</b-form-checkbox>
      </div>
      <template #modal-footer>
        <button @click="$bvModal.hide('modal-tos')" class="btn btn-danger m-1">Saya Tidak Setuju!</button>
        <button @click="continueRegister" :disabled="!(term_1 && term_2 && term_3)" class="btn btn-primary m-1">Saya Paham dan Setuju!</button>
      </template>
    </b-modal>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import { LOGIN, REGISTER, UNREGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import CryptoService from "@/core/services/crypto.service";
// import hmacSHA512 from "crypto-js/hmac-sha512"

export default {
  name: "login-1",
  data() {
    return {
      regstep: 0,
      state: "signin",
      // Remove this dummy login info
      username: "",
      email: "",
      nama_lengkap: "",
      password: "",
      register_data_hash: "",
      login_token: "",
      login_password: "",
      register_token: localStorage.getItem("mw_register_hash"),
      uuid: null,
      limit_1inch: 1,
      limit_matcha: 1,
      term_1: false,
      term_2: false,
      term_3: false,
    };
  },
  watch: {
    nama_lengkap: function(nil) {
      localStorage.setItem("mw_reg_nama_lengkap", nil);
      this.generateRegData();
    },
    email: function(nil) {
      localStorage.setItem("mw_reg_email", nil);
      this.generateRegData();
    },
    password: function() {
      this.generateRegData();
    },
    limit_1inch: function(nil) {
      localStorage.setItem("mw_reg_limit_1inch", nil);
      this.generateRegData();
    },
    limit_matcha: function(nil) {
      localStorage.setItem("mw_reg_limit_match", nil);
      this.generateRegData();
    },
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated", "cekLoginToken"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
    },
    encrypted() {
      return CryptoService.encrypt(
        JSON.stringify({
          username: "gema",
          nama_lengkap: "gema",
          level_akses: 1,
          fitur: null,
          tanggal: Date.now(),
          entropy: "a",
        }),
      );
    },
  },
  mounted() {
    let uid = localStorage.getItem("mw_uid");
    if (uid === null) {
      const nuid = this.create_UUID();
      uid = CryptoService.encrypt(
        JSON.stringify({
          uuid: nuid,
          ua: window.navigator.userAgent,
        }),
      );
      localStorage.setItem("mw_uid", uid);
      this.uuid = nuid;
    } else {
      try {
        uid = CryptoService.decrypt(uid);
        uid = JSON.parse(uid);
        if (uid.ua != window.navigator.userAgent) {
          throw "uuid not match";
        }
        this.uuid = uid.uuid;
      } catch (e) {
        const nuid = this.create_UUID();
        uid = CryptoService.encrypt(
          JSON.stringify({
            uuid: nuid,
            ua: window.navigator.userAgent,
          }),
        );
        localStorage.setItem("mw_uid", uid);
        this.uuid = nuid;
      }
    }

    this.nama_lengkap = localStorage.getItem("mw_reg_nama_lengkap");
    this.email = localStorage.getItem("mw_reg_email");
    this.limit_1inch = localStorage.getItem("mw_reg_limit_1inch");
    this.limit_matcha = localStorage.getItem("mw_reg_limit_matcha");
    this.register_data_hash = localStorage.getItem("mw_reg_register_data_hash");

    let logintoken = this.cekLoginToken;
    // console.log("login precheck1", logintoken.status == "ok");
    // console.log("login precheck2", logintoken.expired > Date.now());
    // console.log("login precheck3", logintoken);
    if (logintoken.status == "ok" && logintoken.expired > Date.now()) {
      this.$router.push({ name: "dashboard" });
    } else {
      this.reloadForm();
    }
  },
  methods: {
    generateRegData() {
      this.register_data_hash = CryptoService.encrypt(
        JSON.stringify({
          nama_lengkap: this.nama_lengkap,
          email: this.email,
          password: CryptoService.md5(this.password),
          uuid: this.uuid,
          limit_1inch: this.limit_1inch,
          limit_matcha: this.limit_matcha,
        }),
      );
      // this.register_data_hash = JSON.stringify({
      //   nama_lengkap: this.nama_lengkap,
      //   email: this.email,
      //   password: CryptoService.md5(this.password),
      //   uuid: this.uuid,
      //   limit_1inch: this.limit_1inch,
      //   limit_matcha: this.limit_matcha,
      // });
      localStorage.setItem("mw_reg_register_data_hash", this.register_data_hash);
    },
    reloadForm() {
      // console.log(this.currentUser);
      this.register_token = localStorage.getItem("mw_register_hash");
      this.username = this.currentUser.username;
    },
    initRegister() {
      this.$refs["modal-tos"].show();
    },
    continueRegister() {
      this.$refs["modal-tos"].hide();
      this.showForm("signup");
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
    },

    onSubmitLogin() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, { authToken: this.login_token, password: this.login_password })
        .then(login => {
          if (login.status != "ok") {
            Swal.fire({
              title: "Gagal!",
              text: login.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            this.$router.push({ name: "dashboard" });
          }

          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        })
        .catch(() => {});
    },

    onSubmitRegister() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store.dispatch(REGISTER, this.register_token).then(register => {
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");

        if (register.status != "ok") {
          Swal.fire({
            title: "Gagal!",
            text: register.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        } else {
          Swal.fire({
            title: "Sukses!",
            text: "Pendaftaran sukses, silahkan login",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          }).then(() => {
            this.reloadForm();
            this.showForm("signin");
          });
        }
      });
    },
    unRegister() {
      this.$store.dispatch(UNREGISTER).then(() => {
        this.reloadForm();
        Swal.fire({
          title: "Berhasil unreg!",
          text: "Silahkan register kembali",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },
    create_UUID() {
      var dt = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    },
  },
};
</script>
